<template>
  <fade-in>
    <div class="header-container">
      <h1 class="white">{{ lang[activeLang]['REQUEST LEGAL ASSISTANCE'] }}</h1>
      <p>
        {{ lang[activeLang]['To request legal assistance online'] }}
        <span v-if="activeLang === 'English'">
          <strong><a href="https://www.aclusocal.org/en/seeking-legal-help-aclu">how the ACLU Foundation of Southern California (&quot;ACLU SoCal&quot;) handles requests</a></strong>
        </span>
        <span v-if="activeLang === 'Español'">
          <strong><a href="https://www.aclusocal.org/en/seeking-legal-help-aclu">how the ACLU Foundation of Southern California accepts cases</a></strong>
        </span>
        {{ lang[activeLang]['for legal assistance'] }}
      </p>
      <p class="box">
        <strong>If you are reaching out about an issue at your California K-12 public school</strong>
        <br><br>
        To contact us about a legal concern related to accurate and inclusive education, such as your school board seeking to ban inclusive curricular materials or your school adopting policies harmful to LGBTQ+ students, please email us with your name, contact information, school district, and description of the issue at <strong><a href="mailto:schools@aclusocal.org">schools@aclusocal.org</a></strong>.
        <br><br>
        Please note that emails sent to <strong><a href="mailto:schools@aclusocal.org">schools@aclusocal.org</a></strong> regarding legal issues other than those relating to inclusive education will not be processed, so please submit all other legal complaints using the form below.
      </p>
      <p>
        {{ lang[activeLang]['Please note that we cannot respond immediately'] }}
      </p>
      <p>
        <strong>{{ lang[activeLang]['All fields are required'] }}</strong> {{ lang[activeLang]['in order for us to process your request for assistance'] }}
      </p>
      <p>
        <em>* </em> {{ lang[activeLang]['Indicates the required fields.'] }}
      </p>
    </div>
  </fade-in>
</template>

<script>
import FadeIn from './FadeIn.vue'

export default {
  name: 'FormFileHeader',
  components: {
    FadeIn
  },
  props: {
    activeLang: {
      type: String,
      default: 'English'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  text-align: left;
}

a {
  text-decoration: none;
  color: #0055aa;
}
.header-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.box {
  border: 2px solid #1B57A3;
  padding: 12px;
  background-color: #FEF5B6;
  border-radius:5px;
}

.spacer {
  width: 2%;
}

.white {
  color:white;
}

.header-logo {
  width: 64px;
  height: 64px;
  color: black;
}

h1 {
  text-align: left;
  font-size: 24px;
}

p {
  text-align: left;
}

.underlined-strong {
  text-decoration: underline;
}

</style>
